import React, { useContext, useEffect, useRef, useState } from "react"
import type { BannerData, BannerProps, BannerResData } from "./Banner.d"
import { LocationContext } from "../../../contexts/Location"
import { LanguageContext } from "../../../contexts/Language"
import { Link } from "../../atoms/Link"
import tw from "twin.macro"
import { css } from "@emotion/react"
import Icon from "../../atoms/Icon"
import { HeaderContext } from "../../../contexts/Header"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { RecentlyViewed } from "../RecentlyViewed"
import { Favorites } from "../Favorites"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Badge } from "../../atoms/Badge"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Popover, POPOVER_ORIGINS } from "../../atoms/Popover"
import { Button } from "../../atoms/Button"
import { LocationSelector } from "../../molecules/LocationSelector"
import { LocalStorageValue } from "../../../clients/LocalStorageClient"
import { BannerContent } from "."
import { Switch } from "../../atoms/Switch"
import { Refresh } from "../../atoms/Refresh"

enum OPEN_POPOVER {
  DEALERS,
  LANGUAGE,
}

const Banner = ({}: BannerProps): JSX.Element => {
  const [{ zip, dealers }] = useContext(LocationContext)
  const { open, setOpen, pageType } = useContext(HeaderContext)
  const [
    {
      favoritedVehicles,
      favoritedDealer,
      favoritedAccessories,
      favoritedOffers,
    },
  ] = useContext(FavoritesContext)
  const { language, _ } = useContext(LanguageContext)
  const [mobileView] = useState(null)
  const [banners, setBanners] = useState<BannerData[]>([])
  const [activeBanner, setActiveBanner] = useState<BannerData>()
  const [switchOn, setSwitchOn] = useState(false)
  const [bannerIterate, setBannerIterate] = useState(true)
  const intervalId = useRef(null)

  // Getting Banner Data
  const {
    siteBanners,
    acquisitionSalesEventBanners,
    acquisitionSalesEventBannersES,
    retentionSalesEventBanners,
    retentionSalesEventBannersES,
  } = useStaticQuery(BannersQuery)
  const acquisitionSalesEventBannerData = {
    en: acquisitionSalesEventBanners?.nodes[0]?.salesEventBanner,
    es: acquisitionSalesEventBannersES?.nodes[0]?.salesEventBanner,
  }
  const acquisitionSEBannerPageTypes =
    language === "es"
      ? acquisitionSalesEventBannersES?.nodes[0]?.salesEventBannerPageTypes
          ?.pageTypeOptions
      : acquisitionSalesEventBanners?.nodes[0]?.salesEventBannerPageTypes
          ?.pageTypeOptions

  const isMultiRetentionSE = retentionSalesEventBanners?.nodes.length > 1

  const primaryRetentionSE = isMultiRetentionSE
    ? retentionSalesEventBanners?.nodes?.find(
        (item: any) => item.salesEventBanner !== null,
      )
    : retentionSalesEventBanners?.nodes?.[0]
  const primaryRetentionSE_es = isMultiRetentionSE
    ? retentionSalesEventBannersES?.nodes?.find(
        (item: any) => item.salesEventBanner !== null,
      )
    : retentionSalesEventBanners?.nodes?.[0]

  const retentionSalesEventBannerData = {
    en: primaryRetentionSE?.salesEventBanner,
    es: primaryRetentionSE_es?.salesEventBanner,
  }
  const retentionSEBannerPageTypes =
    language === "es"
      ? primaryRetentionSE?.salesEventBannerPageTypes?.pageTypeOptions
      : primaryRetentionSE_es?.salesEventBannerPageTypes?.pageTypeOptions

  const activeAcquisitionSE =
    (language === "es" && acquisitionSalesEventBannerData.es) ||
    (language == "en" && acquisitionSalesEventBannerData.en)
  const activeRetentionSE =
    (language === "es" && retentionSalesEventBannerData.es) ||
    (language == "en" && retentionSalesEventBannerData.en)

  // Setting Banner Data
  useEffect(() => {
    // Pages each SE banner will appear on
    const showAcquisitionSEPageTypes =
      acquisitionSEBannerPageTypes?.map(type => type?.replace("page.", "")) ||
      []
    const showRetentionSEPageTypes =
      retentionSEBannerPageTypes?.map(type => type?.replace("page.", "")) || []

    // Active Acquisition and Retention Sales Event banners on Home page
    if (
      activeAcquisitionSE &&
      showAcquisitionSEPageTypes?.includes(pageType) &&
      activeRetentionSE &&
      showRetentionSEPageTypes?.includes(pageType) &&
      pageType === "home"
    ) {
      const acquisitionSalesEventBanner: BannerData =
        language === "es"
          ? acquisitionSalesEventBannerData.es
          : acquisitionSalesEventBannerData.en
      const retentionSalesEventBanner: BannerData =
        language === "es"
          ? retentionSalesEventBannerData.es
          : retentionSalesEventBannerData.en

      setActiveBanner(acquisitionSalesEventBanner)
      setBanners([acquisitionSalesEventBanner, retentionSalesEventBanner])
      return
    }
    // Active Acquisition Sales Event
    if (activeAcquisitionSE && showAcquisitionSEPageTypes.includes(pageType)) {
      setActiveBanner(
        language === "es"
          ? acquisitionSalesEventBannerData.es
          : acquisitionSalesEventBannerData.en,
      )
      return
    }
    // Active Retention Sales Event
    if (activeRetentionSE && showRetentionSEPageTypes.includes(pageType)) {
      setActiveBanner(
        language === "es"
          ? retentionSalesEventBannerData.es
          : retentionSalesEventBannerData.en,
      )
      return
    }
    // Default Site Banner
    setActiveBanner(
      language === "es" ? siteBanners.bannerES : siteBanners.banner,
    )
  }, [])

  // Setting active banner when switch changes
  useEffect(() => {
    if (banners?.length !== 2) return
    if (switchOn) {
      setActiveBanner(banners[1])
    } else {
      setActiveBanner(banners[0])
    }
  }, [switchOn])

  // Setting interval to iterate through 2 SE Banners on home page
  useEffect(() => {
    if (
      !(
        acquisitionSalesEventBannerData &&
        retentionSalesEventBannerData &&
        pageType === "home"
      )
    )
      return

    intervalId.current = setInterval(() => {
      setSwitchOn(switchOn => !switchOn)
    }, 8000)

    return () => clearInterval(intervalId.current)
  }, [])

  // Stop 2 SE Banners iterating on the homepage
  const stopInteration = () => {
    clearInterval(intervalId.current)
    setBannerIterate(false)
  }

  const hasConfirmedZip: LocalStorageValue =
    LocalStorageClient.read("confirmedZip") || false

  const [confirmedZip, setConfirmedZip] = useState(hasConfirmedZip)
  const [showZipEntry, setShowZipEntry] = useState(false)

  // const recentlyViewed = LocalStorageClient.read("recentlyViewed")
  // const recentlyViewedCount = recentlyViewed?.length || 0

  let favoritesCount = favoritedDealer ? 1 : 0
  favoritesCount += favoritedVehicles?.length || 0
  favoritesCount += favoritedAccessories?.length || 0
  favoritesCount += favoritedOffers?.length || 0

  const favoritesIndex = 98
  const recentlyViewedIndex = 97

  const backgroundColor =
    activeBanner?.backgroundColor?.hex &&
    css`
      @media (min-width: 1024px) {
        background-color: ${activeBanner.backgroundColor.hex};
      }
    `

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  // Popover State Control
  const [openPopover, setOpenPopover] = useState<OPEN_POPOVER>(null)
  const closePopover = () => {
    setOpenPopover(null)
    setShowZipEntry(false)
  }

  const openLanguagePopover = () => setOpenPopover(OPEN_POPOVER.LANGUAGE)
  const openDealersPopover = () => setOpenPopover(OPEN_POPOVER.DEALERS)

  const toggleOpenLanguage = () =>
    openPopover === OPEN_POPOVER.LANGUAGE
      ? closePopover()
      : openLanguagePopover()

  const toggleOpenDealers = () =>
    openPopover === OPEN_POPOVER.DEALERS ? closePopover() : openDealersPopover()

  const toggleRoute = () => {
    if (typeof window === "undefined") return
    const url = new URL(window.location.href)
    const path = url.pathname.split("/").filter(String)
    const newLanguage = language == "es" ? "" : "es"

    navigate(
      `/${newLanguage && newLanguage + "/"}${path
        .splice(path.indexOf(language) + 1)
        .join("/")}`,
    )
  }

  const hasRecentlyViewedVins = Array.isArray(
    LocalStorageClient.read("recentlyViewed"),
  )

  return (
    <>
      <Link
        to="#main"
        target="_self"
        css={[
          tw`fixed top-[-200px] left-[45%] text-lg rounded-b-xl bg-gray-800 text-white px-6 py-3 inline-block focus:top-0`,
          css`
            &:focus-visible {
              outline-color: #c00000;
              outline-style: dotted;
              outline-offset: 3px;
              outline-width: 2px;
            }
          `,
        ]}
      >
        {_("Skip to Main Content")}
      </Link>
      <div
        css={[
          tw`lg:(h-12 w-full block)`,
          !activeBanner?.backgroundColor?.hex && tw`lg:(bg-gray-50)`,
          activeBanner?.backgroundColor?.hex && backgroundColor,
        ]}
      >
        <div
          css={[
            tw`block lg:flex justify-between items-center h-full max-w-[1440px] px-10 mb-28 mx-auto relative`,
          ]}
        >
          <div css={tw`hidden mb-6 lg:(mb-0 flex)`}>
            {banners?.length === 2 && (
              <Refresh
                label=""
                checked={switchOn}
                onChange={() => {
                  setSwitchOn(!switchOn)
                  bannerIterate && stopInteration() // Once user uses switch, we're no longer iterating through the banners
                }}
                css={tw`mr-4 lg:(mr-2)`}
              />
            )}
            {activeBanner && <BannerContent bannerData={activeBanner} />}
          </div>
          <div css={[tw`block mb-6 lg:(hidden)`]}>
            {banners?.length === 2 && (
              <>
                <BannerContent bannerData={banners[0]} />
                <BannerContent bannerData={banners[1]} />
              </>
            )}
            {activeBanner && !banners?.length && (
              <BannerContent bannerData={activeBanner} />
            )}
          </div>
          <nav
            css={[
              tw`flex items-center w-auto justify-center xl:(w-auto h-full list-none)`,
            ]}
          >
            {/* Dealer State */}
            <li css={[tw`inline-flex relative`]}>
              <Link
                animated
                animatedThin
                css={[
                  tw`mx-4 flex justify-items-center items-center cursor-pointer`,
                  tw`md:(py-0 after:(left-8 right-0 w-auto))`,
                ]}
                onClick={() => {
                  confirmedZip
                    ? navigate(language === "es" ? "/es/dealers" : "/dealers")
                    : toggleOpenDealers()
                  trackTealEvent({
                    tealium_event: "nav_click",
                    click_text: `top nav: ${
                      dealers?.length || "FIND"
                    } Dealers Near ${zip}`,
                  })
                }}
              >
                <span css={[tw`hidden lg:(inline-flex text-sm)`]}>
                  <Icon.Marker
                    color="red-400"
                    css={[tw`hidden`, tw`lg:(inline-flex w-4 mx-2)`]}
                  />

                  <span
                    css={[
                      tw`pt-0.5 text-xs whitespace-nowrap`,
                      tw`xl:text-base`,
                    ]}
                  >
                    {dealers && dealers.length > 0
                      ? `${dealers?.length} ${_("Dealers Near")} ${zip}`
                      : _("Change Location")}
                  </span>
                </span>
                <span
                  css={[
                    tw`border rounded-lg border-gray-400 py-3 w-16 h-16 relative`,
                    tw`lg:(border-0 w-auto py-0 h-auto)`,
                  ]}
                >
                  <Icon.Marker
                    color="gray-900"
                    css={[tw`w-5 mx-auto pt-1 lg:(hidden)`]}
                  />
                  <span
                    css={[tw`text-xs w-full block text-center lg:(hidden)`]}
                  >
                    {zip}
                  </span>
                </span>
              </Link>
              <Popover
                open={openPopover === OPEN_POPOVER.DEALERS}
                origin={POPOVER_ORIGINS.BOTTOM_RIGHT}
                onClose={closePopover}
                css={[
                  tw`bg-white w-[24em] p-8 pt-4`,
                  language === "es" &&
                    tw`w-[26rem] max-w-[100vw] pt-8 lg:w-[30.75rem]`,
                  `@media (max-width: 850px) {
                  width: 20em; left: -20px; position: absolute; top: -150px;
                  }`,
                ]}
              >
                <div
                  css={[tw`text-lg`]}
                  aria-describedby="ZipCodeDesc"
                  aria-label="Confirm Your Zip Code"
                  aria-labelledby="ZipCodeTitle"
                  role="dialog"
                >
                  {_("Current Zip")}:{" "}
                  <span css={[tw`font-semibold`]} id="ZipCodeTitle">
                    {zip}
                  </span>
                  {!showZipEntry && (
                    <div css={[tw`text-sm pt-4`]}>
                      {_("Please confirm your zip code to proceed.")}
                    </div>
                  )}
                </div>
                {!showZipEntry && (
                  <div css={[tw`bg-white`]}>
                    <div
                      css={[
                        tw`flex flex-row gap-2 justify-center mt-6 flex-wrap`,
                      ]}
                    >
                      <Button
                        primary
                        css={[tw`whitespace-nowrap`]}
                        onClick={() => {
                          LocalStorageClient.write("confirmedZip", true)
                          LocalStorageClient.delete("sourceZip")
                          setConfirmedZip(true)
                          navigate(
                            language === "es" ? "/es/dealers" : "/dealers",
                          )
                          closePopover()
                          trackTealEvent({ customer_zip: zip })
                        }}
                        analytics-id="Confirm:top nav:nearby dealers"
                      >
                        {_("Confirm")}
                      </Button>
                      <Button
                        secondary
                        css={[tw`whitespace-nowrap`]}
                        onClick={() => {
                          setShowZipEntry(true)
                        }}
                      >
                        {_("Change Zip")}
                      </Button>
                    </div>
                  </div>
                )}

                {showZipEntry && (
                  <LocationSelector
                    focusColor={"gray"}
                    fromBanner={true}
                    setZipIsConfirmed={setConfirmedZip}
                    location="global"
                    analyticsId="top nav:nearby dealers"
                  />
                )}
              </Popover>
            </li>

            {/* Language */}
            <li
              css={[
                tw`hidden lg:(inline-flex relative pt-0.5 hover:cursor-pointer)`,
              ]}
            >
              <Link
                animated
                animatedThin
                onClick={() => {
                  toggleOpenLanguage()
                }}
                css={[
                  tw`xl:ml-4 flex justify-items-center items-center py-0 after:(left-8 right-0 w-auto)`,
                ]}
                target="_self"
              >
                <Icon.Language
                  color="red-400"
                  css={[tw`hidden`, tw`lg:(inline-flex w-4 mx-2)`]}
                />
                <span
                  css={[
                    tw`text-xs`,
                    tw`xl:text-base`,
                    css`
                      &:after {
                        content: "";
                        transform: scaleX(0);
                        ${tw`absolute w-full rounded-full h-0.5 bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
                      }
                      button:hover &:after a:hover &:after {
                        transform: scaleX(1);
                        ${tw`origin-bottom-left`}
                      }
                    `,
                  ]}
                >
                  {language === "en" ? "Español" : "English"}
                </span>
              </Link>
              <Popover
                open={openPopover === OPEN_POPOVER.LANGUAGE}
                origin={POPOVER_ORIGINS.BOTTOM_RIGHT}
                onClose={closePopover}
                css={[tw`bg-white w-[24em] p-8 pt-6`]}
              >
                <div css={[tw`bg-white`]}>
                  <h3 css={[tw`text-xs`]}>{_("Set Your Language")}</h3>
                  <div css={[tw`flex flex-row justify-center mt-4`]}>
                    <Button
                      primary
                      onClick={() => {
                        toggleRoute(),
                          trackTealEvent({ tealium_event: "language_selector" })
                      }}
                      analytics-id="language selector:top nav:"
                    >
                      {language === "en"
                        ? "Cambiar a Español"
                        : "Change to English"}
                    </Button>
                  </div>
                </div>
              </Popover>
            </li>
            <li css={[tw`inline-flex relative lg:(hidden)`]}>
              <button
                css={[
                  tw`bg-opacity-0 px-4 h-full flex justify-items-center items-center transition-colors`,
                  tw`hover:(bg-opacity-100)`,
                  tw`focus:(bg-opacity-100)`,
                ]}
                onClick={() => {
                  open === recentlyViewedIndex
                    ? setOpen(null)
                    : setOpen(recentlyViewedIndex)
                }}
              >
                <span
                  css={[
                    tw`border rounded-lg border-gray-400 p-2 w-16 h-16 relative py-5`,
                  ]}
                >
                  {hasRecentlyViewedVins ? (
                    <span
                      css={[
                        tw`absolute -right-1 -top-1 bg-toyotaRed rounded-full w-4 h-4 flex items-center justify-center lg:(hidden)`,
                      ]}
                    />
                  ) : null}
                  <Icon.History color="gray-900" css={[tw`h-5 mx-auto`]} />
                </span>
              </button>
            </li>
            <li css={[tw`inline-flex relative lg:(hidden)`]}>
              <button
                css={[
                  tw`bg-opacity-0 px-4 h-full flex justify-items-center items-center transition-colors`,
                  tw`hover:(bg-opacity-100)`,
                  tw`focus:(bg-opacity-100)`,
                ]}
                onClick={() => {
                  open === favoritesIndex
                    ? setOpen(null)
                    : setOpen(favoritesIndex)
                }}
              >
                <span
                  css={[
                    tw`border rounded-lg border-gray-400 p-2 w-16 h-16 relative py-4`,
                  ]}
                >
                  {favoritesCount > 0 && (
                    <span
                      css={[
                        tw`absolute -right-1 -top-1 bg-toyotaRed rounded-full w-4 h-4 flex items-center justify-center lg:(hidden)`,
                      ]}
                    />
                  )}
                  <Icon.Heart
                    color="gray-900"
                    css={[tw`h-6 mx-auto pt-1`]}
                    filled
                  />
                </span>
              </button>
              {/* <Favorites open={open === 98} /> */}
            </li>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Banner

export const BannersQuery = graphql`
  query BannersQuery {
    siteBanners: sanitySiteSettings {
      banner {
        ...Banner
      }
      bannerES {
        ...Banner
      }
    }

    acquisitionSalesEventBanners: allSanitySalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "en" } }
    ) {
      nodes {
        salesEventBanner {
          ...Banner
        }
        salesEventBannerPageTypes {
          pageTypeOptions
        }
      }
    }
    acquisitionSalesEventBannersES: allSanitySalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "es" } }
    ) {
      nodes {
        salesEventBanner {
          ...Banner
        }
        salesEventBannerPageTypes {
          pageTypeOptions
        }
      }
    }

    retentionSalesEventBanners: allSanityRetentionSalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "en" } }
    ) {
      nodes {
        salesEventBanner {
          ...Banner
        }
        salesEventBannerPageTypes {
          pageTypeOptions
        }
      }
    }
    retentionSalesEventBannersES: allSanityRetentionSalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "es" } }
    ) {
      nodes {
        salesEventBanner {
          ...Banner
        }
        salesEventBannerPageTypes {
          pageTypeOptions
        }
      }
    }
  }
`
